import { useState } from "react";
import "./App.css";

function App() {
  const [count, setCount] = useState(62);

  return (
    <div>
      <div className="box">
        <div className="head">
          <div className="hair-black"></div>

          <div className="eye-left"></div>

          <div className="pupil-top-left"></div>

          <div className="blush-lef"></div>

          <div className="eye-right"></div>

          <div className="pupil-top-rigth"></div>

          <div className="blush-rigth"></div>

          <div className="nose"></div>

          <div className="nostril-left"></div>

          <div className="nostril-rigth"></div>

          <div className="horn-left"></div>
          <div className="horn-rigth"></div>

          <div className="ear-left"></div>
          <div className="ear-rigth"></div>
        </div>
      </div>

      <div className="counter">
        <button className="btn" onClick={(e) => setCount(count - 1)}>
          -
        </button>
        <span className="count-text">{count}</span>
        <button className="btn" onClick={(e) => setCount(count + 1)}>
          +
        </button>
      </div>
    </div>
  );
}

export default App;
